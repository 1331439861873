import { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import phone from './phone.svg';
import axios from 'axios';
import emailjs from '@emailjs/browser';

function App() {
  const [email, setEmail] = useState("");
  const [deviceOS, setDeviceOS] = useState("");
  const [buttonLabel, setButtonLabel] = useState("Rejoins-nous !");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmail("");
    setButtonLabel("Merci ! 🚀");

    const timestamp = new Date().toISOString();
    await axios.post("https://sheetdb.io/api/v1/3iaibjqpw1u6j", {
      data: { email, deviceOS, timestamp },
    });

    emailjs.send(
      "service_vwghqjw",
      "template_02rs9dr",
      { to_email: email },
      "zTzhVKh0L1NeSbMTl"
    );
  };

  useEffect(() => {
    if (/^\S+@\S+\.\S+$/.test(email))
      setButtonLabel("Rejoins-nous !");
  }, [email]);

  useEffect(() => {
    const userAgent = navigator.userAgent || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceOS("iOS");
    } else if (/android/i.test(userAgent)) {
      setDeviceOS("Android");
    } else {
      setDeviceOS("Unknown");
    }
  }, []);

  return (
    <div 
      className="h-screen flex flex-col"
      style={{ fontFamily: 'Fraunces, serif' }}
    >
      <Header />
      <div className="flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 p-6 lg:px-16">
        <div className="flex-1 lg:flex-none lg:w-1/2 space-y-6 lg:space-y-24">
          <div>
            <h1 className="text-left text-lg lg:text-4xl font-bold">
              Préinscrivez-vous à la bêta de qalmo,
            </h1>
            <h1 className="text-left text-2xl lg:text-5xl font-bold">
              l'application où chaque tâche devient un partage.
            </h1>
          </div>
          <form 
            onSubmit={handleSubmit}
            className="flex justify-center space-x-2"
          >
            <input 
              style={{ borderColor: "#DADEE5" }}
              className="text-xs lg:text-base rounded-2xl px-3 lg:px-6 lg:py-2 border"
              name="email"
              placeholder="john.doe@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button 
              type="submit"
              style={/^\S+@\S+\.\S+$/.test(email)
                ? { backgroundColor: "#000B23", color: "white" }
                : { backgroundColor: "#ECEDF1", color: "#798194" }
              }
              className="w-40 text-xs lg:text-base rounded-2xl px-6 py-2"
              disabled={!/^\S+@\S+\.\S+$/.test(email)}
            >
              {buttonLabel}
            </button>
          </form>
        </div>
        <div className="flex w-full items-center justify-center pb-14 lg:pb-0">
          <img className="-rotate-12 w-40 lg:w-52" src={phone} alt="phone" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
